<template>
<div class="page-box">
  <img @click="backClick" class="get-back" src="@/assets/get-back.png" alt="">
  <div class="detail" v-if="detailInfo">
    <div class="title-name">
      <p>{{detailInfo.name}}
        <span class="ht">行业</span>
      </p>
      <p>{{detailInfo.goodsClassShow}}</p>
    </div>
    <div class="video">
      <div class="video-content">
        <!-- <div class="thumb">
          <img class="thumb" :src="detailInfo.previewOfficialImageUrls[0]" alt="">
          <div class="g-suspend g-align-center" v-if="detailInfo.videosUrl && detailInfo.videosUrl.length > 0 && detailInfo.videosUrl[0]">
            <img class="pause-img" src="@/assets/images/pause-img.png" alt="" @click="hendlePlay(true, detailInfo.videosUrl[0])">
          </div>
        </div> -->
        <van-swipe class="thumb" :autoplay="3000" indicator-color="white" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img class="thumb" :src="item.url" alt="">
            <div class="g-suspend g-align-center" v-if="index == 0 && detailInfo.videosUrl && detailInfo.videosUrl.length > 0 && detailInfo.videosUrl[0]">
              <img class="pause-img" src="@/assets/images/pause-img.png" alt="" @click="hendlePlay(true, detailInfo.videosUrl[0])">
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="sm-img">
          <!-- <img :src="item" alt="" v-for="(item,index) in detailInfo.rotationImagesUrl" :key="index"> -->
          <img :src="item.url" alt="" v-for="(item,index) in bannerList" :key="index">
        </div>
        <div class="text">
          <div class="name">
            {{detailInfo.name}}
            <span v-if="detailInfo.tagContent">{{detailInfo.tagContent}}</span>
          </div>
          <div class="tip">{{ detailInfo.brief }}</div>
        </div>
        <div class="btn" @click.stop="onChange(true, 'makeShow')">
          <span>预约演示</span>
        </div>
      </div>
    </div>
    <div class="title-line">
      <img class="img1" src="@/assets/xian.png" alt="">
      <span>商品定价</span>
      <img class="img2" src="@/assets/xian.png" alt="">
    </div>
    <div class="spec-box">
      <div class="content">
        <van-swipe class="van-dom-sw" ref="swipeRef" @change="changeSw" indicator-color="#fff" :show-indicators="false">
          <van-swipe-item style="height: auto" v-for="(item1,index1) in detailInfo.sysGoordsSpecVoList" :key="index1">
            <div class="box-item">
              <div class="spec-item" >
                <div class="header">
                  <!-- <div class="jb-img" v-show="item1.recommedFlag">推荐购买</div> -->
                  <img class="jb-img" v-show="item1.recommedFlag" src="@/assets/recommed-img.png" alt="">
                  <div class="tip">
                    <img class="tip-img1" src="@/assets/tip-img1.png" alt="">
                    {{item1.name}}
                    <img class="tip-img2" src="@/assets/tip-img2.png" alt="">
                  </div>
                </div>
                <div class="desc">
                  <span>{{ item1.remarks }}</span>
                </div>
              </div>
              <div class="bottom-price"><span>￥</span>{{changePrice(item1.reallyProductFee + item1.reallyServiceFee,'price')}}/年</div>
            </div>
          </van-swipe-item>
          <template #indicator>
              <div class="custom-indicator">
                <div class="item" v-for="(item1,index1) in detailInfo.sysGoordsSpecVoList" :key="index1" :class="indexD == index1?'active':''"></div>
              </div>
            </template>
        </van-swipe>
        <van-sticky @change="changeSticky($event, 1)">
          <div class="sticky-box" v-if="stickyShow">
            <img @click="leftClick(1)" class="box-img box-left" src="@/assets/jt-left.png" alt="" srcset="">
            <span>{{ detailInfo.sysGoordsSpecVoList[indexD].name }}</span>
            <img @click="leftClick(2)" class="box-img box-right" src="@/assets/jt-left.png" alt="" srcset="">
          </div>
        </van-sticky>
        <div class="sys-card card-item" style="margin-top: 30px" :key="`${indexD}-sys`" v-if="submitForm && submitForm.moduleList && submitForm.moduleList.length > 0">
          <div class="header-img">
            <img src="@/assets/bc-title.png" alt="">
            <div class="h-title">
              <span>软件</span>
            </div>
          </div>
          <div class="table-card" v-for="(item2,index2) in submitForm.sysList" :key="index2">
            <div class="tiotle">系统应用>{{item2.sysName}}</div>
            <div class="t-cont" v-for="(item3, index3) in item2.List" :key="index3">
              <div class="product-name">
                <span>{{item3[0].productName}}</span>
              </div>
              <div class="fun-name td-name" v-if="item3 && item3.length > 0">
                <div class="d-ct"  v-for="(item4,index4) in item3" :key="index4">
                  <span>{{ item4.name ? item4.name : '' }} {{ applicableWay(item4.applicablePlatform) }}</span>
                  <div class="img-div">
                    <img src="@/assets/yes.png" alt="" v-if="getCode(detailInfo.sysGoordsSpecVoList[indexD].moduleList || [],item4.id)">
                    <img src="@/assets/err.png" alt="" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sys-card card-item" v-if="submitForm && submitForm.resourceBundles && submitForm.resourceBundles.length > 0">
          <div class="header-img">
            <img src="@/assets/bc-title.png" alt="">
            <div class="h-title">
              <span>资源包</span>
            </div>
          </div>
          <div class="table-card" >
            <div class="tiotle">资源包</div>
            <div class="t-cont" v-for="(item, index) in submitForm.resourceBundles" :key="index">
              <div class="product-name">
                <span>{{ item.typeShow }}</span>
              </div>
              <div class="fun-name td-name">
                <div class="d-ct"  >
                  <span>{{ item.name ? item.name : '' }}&nbsp;&nbsp;({{item.quantityFlow}}{{item.unitName}}/{{ item.validityName }})</span>
                  <div class="img-div">
                    <img src="@/assets/yes.png" alt="" v-if="getCode(detailInfo.sysGoordsSpecVoList[indexD].resourceBundles || [],item.id)">
                    <img src="@/assets/err.png" alt="" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sys-card card-item" v-if="submitForm && submitForm.hardware && submitForm.hardware.length > 0">
          <div class="header-img">
            <img src="@/assets/bc-title.png" alt="">
            <div class="h-title">
              <span>硬件</span>
            </div>
          </div>
          <div class="table-card" >
            <div class="tiotle">硬件</div>
            <div class="t-cont" v-for="(item, index) in submitForm.hardware" :key="index">
              <div class="product-name">
                <span>{{ item.className }}</span>
              </div>
              <div class="fun-name td-name">
                <div class="d-ct"  >
                  <span>{{ item.name }}({{item.quantity}}{{ item.unitShow }})</span>
                  <div class="img-div">
                    <img src="@/assets/yes.png" alt="" v-if="getCode(detailInfo.sysGoordsSpecVoList[indexD].hardware || [],item.id)">
                    <img src="@/assets/err.png" alt="" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-sticky @change="changeSticky($event, 2)"></van-sticky>
      </div>
    </div>
    <div v-for="(item, index) in sysGoodsParticularsVoList" :key="index">
      <div class="title-line">
        <img class="img1" src="@/assets/xian.png" alt="">
        <span>{{ item.title }}</span>
        <img class="img2" src="@/assets/xian.png" alt="">
      </div>
      <div class="details-sys">
        <div :class="[item.details ? 'details-introduce' : '']" v-if="item.brief">
          <span>{{ item.brief }}</span>
        </div>
        <div v-if="item.details"  v-html="item.details"></div>
      </div>
    </div>
    <VideoModel v-model="isplayShow" :typePrice="1" :item="isplayObj"></VideoModel>
  </div>
  <BottomDom v-if="detailInfo" ref="footerDom"></BottomDom>
</div>
</template>

<script>
import { industrySolutionDetails, goodsDetailsByParticulars} from '@/util/api'
import BottomDom from '@/views/components/bottom-dom/index.vue'
export default {
  components:{
    BottomDom,
    VideoModel: resolve => (require(['@/views/components/fiche/video-model.vue'], resolve))
  },
  data() {
    return {
      stickyShow: false,
      indexD: 0,
      detailInfo: null,
      isplayShow: false,
      isplayObj: {},
      appList: [
        { code: 1, name: 'pc端' },
        { code: 2, name: 'H5' },
        { code: 3, name: '小程序' },
        { code: 4, name: '公众号' },
        { code: 5, name: 'APP' },
        { code: 6, name: 'API对接' },
      ],
      submitForm: {},
      sysGoodsParticularsVoList: [],
      bannerList: [],
    };
  },
  computed: {},
  created() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    changeSticky(e, val) {
      console.log(e, val)
      if (val == 1) {
        this.stickyShow = e
      } else {
        if (e) {
          this.stickyShow = false
        } else {
          this.stickyShow = true
        }
      }
    },
    leftClick (val) {
      if (val == 1) {
        if (this.indexD == 0) {
          this.indexD = this.detailInfo.sysGoordsSpecVoList.length - 1
        } else {
          this.indexD--
        }
        this.$refs.swipeRef.prev()
      } else {
        if (this.indexD == (this.detailInfo.sysGoordsSpecVoList.length - 1)) {
          this.indexD = 0
        } else {
          this.indexD++
        }
        this.$refs.swipeRef.next()
      }
    },
    applicableWay(val) {
      let arr = []
      if (val != undefined) {
        if (val.split(',').length > 0) {
          val.split(',').map(item => {
            let obj =  this.appList.find(o => o.code == item)
            if (obj.name) {
              arr.push(obj.name)
            }
          })
        }
        return `（${arr.join(',')}）`
      }
    },
    hendlePlay(val, item) {
      this.isplayShow = val
      this.isplayObj = {
        videoAddress: item
      }
    },
    backClick() {
      document.title = '聚优企'
      this.$router.push({ name: 'home' })
    },
    onChange(index) {
      this.$refs.footerDom.modalOff(index)
    },
    getCode(arr, codeId){
      let offIndex = arr.findIndex((v)=> v.id == codeId)
      return offIndex> -1 ? true: false
    },
    changeSw(val){
      this.indexD = val
      // this.initData()
    },
    getDetail(id){
      this.bannerList = []
      industrySolutionDetails({id: id}).then((res)=>{
        this.detailInfo = res
        if (this.detailInfo.previewOfficialImageUrls && this.detailInfo.previewOfficialImageUrls.length > 0) {
          this.detailInfo.previewOfficialImageUrls.map(i => {
            this.bannerList.push({type: 2, url: i})
          })
        }
        if (this.detailInfo.rotationImagesUrl && this.detailInfo.rotationImagesUrl.length > 0) {
          this.detailInfo.rotationImagesUrl.map(i => {
            this.bannerList.push({type: 2, url: i})
          })
        }
        document.title = res.name //此处写默认的title
        let list = this.detailInfo.sysGoordsSpecVoList
        if(list && list.length > 0){
          list.map(obj => {
            if (obj.moduleList && obj.moduleList.length > 0) {
              obj.sysList = []
              const sysMap = {}
              const productMap = {}
              obj.moduleList.map(item => {
                sysMap[item.sysCode] = sysMap[item.sysCode] || {}
                if (!sysMap[item.sysCode].show) { // 根据每个商品下的系统合并
                  const arr = obj.moduleList.filter((v) => (v.sysCode == item.sysCode))
                  let list = []
                  arr.map(item2 => {
                    productMap[item2.productGroup] = productMap[item2.productGroup] || {}
                    if (!productMap[item2.productGroup].show) {
                      const arr2 = arr.filter((v) => (v.productGroup == item2.productGroup))
                      list.push(arr2)
                      productMap[item2.productGroup].show = true
                    }
                  })
                  obj.sysList.push({sysName: item.sysName, sysCode: item.sysCode, List: list})
                  sysMap[item.sysCode].show = true
                }
              })
            }
            return obj
          })
          this.submitForm = list[list.length - 1]
          console.log('数据是的是', this.submitForm)
          console.log('数据是的是', this.detailInfo)
        }
      })
      goodsDetailsByParticulars({id: id}).then(res => {
        this.sysGoodsParticularsVoList = res
      })
    }
  },
};
</script>
<style lang='less' scoped>
// .g-suspend {
//   width: 100px;
//   height: 100px;
//   margin: 0 auto;
// }
.page-box{
  position: relative;
  background: #4E9AF9;
  box-sizing: border-box;
  background:url('../../assets/bac.png') no-repeat center center;
  background-size: 100% 100%;
  .get-back {
    position: fixed;
    z-index: 999;
    width: 80px;
    height: 80px;
    top: 45px;
    left: 19px;
  }
  .detail {
    width: 100%;
    // height: 100%;
    padding: 0px 22px;
    padding-top: 80px;
    box-sizing: border-box;
    margin-bottom: 40px;
    .title-name{
      margin-bottom: 80px;
      margin-left: 44px;
      .ht{
        color: #F3BC54;
        margin-left: 20px;
      }
      p,span{
        margin: 0;
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        font-style: italic;
        color: #FFFFFF;
        text-shadow: 0px 0px 16px rgba(57,56,61,0.24);
      }
      p:nth-of-type(2){
        margin-top: 12px;
      }
    }
    .video-content{
      background: #fff;
      padding-bottom: 40px;
    }
    .video{
      // padding: 10px 10px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 12px solid;
      border-image: linear-gradient(0deg, #B4D4FF, #F6FDFE) 12 12;
      border-radius: 12px;
      .thumb{
        position: relative;
        width: 100%;
        height: 400px;
      }
      .sm-img{
        display: flex;
        align-items: center;
        margin: 40px 14px 40px 14px;
        justify-content: space-between;
        overflow: hidden;
        overflow-x: auto;
        img{
          width: 200px;
          height: 150px;
          margin-right: 22px;
        }
        img:last-child{
          margin-right: 0px;
        }
      }
      .text{
        margin: 0px 49px 0px 49px;
        .name{
          display: flex;
          // align-items: center;
          color: #3359D8;
          font-size: 36px;
          span:nth-of-type(1){
            max-width: 50%;
            background: #FFEFDD;
            padding: 8px 20px;
            color: #FFA03A;
            font-size: 24px;
            margin-left: 10px;
          }
        }
        .tip{
          color: #696969;
          font-size: 30px;
          margin-top: 24px;
        }
      }
      .btn{
        margin: 0 auto;
        margin-top: 40px;
        width: 516px;
        text-align: center;
        color: #fff;
        padding: 20px 0px;
        background: linear-gradient(90deg, #78B5F4, #578AF0);
        border-radius: 40px;
        span {
          font-size: 34px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
    .title-line{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 45px 0px;
      span {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0px 20px;
      }
      .img1 {
        width: 190px;
        height: 2px;
      }
      .img2 {
        width: 190px;
        height: 2px;
        transform: rotateZ(-180deg);
      }
    }
    .spec-box{
      .content{
        .box-item{
          position: relative;
          border-radius: 20px;
          overflow: hidden;
          background: #fff;
          .desc{
            color: #696969;
            margin: 57px 49px 0px 49px;
            height: 160px;
            span {
              font-size: 28px;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              word-break: break-all;
              line-height: 32px;
            }
          }
          .bottom-price{
            width: 100%;
            height: 108px;
            line-height: 108px;
            text-align: center;
            font-size: 34px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            color: #EA7505;
            margin-top: 57px;
            span{
              font-size: 26px;
              color: #EA7505;
            }
            background: #FFE0C2;
          }
        }
        .spec-item{
          width: 100%;
          position: relative;
          background: #fff;

          // .jb-img{
          //   position: absolute;
          //   top: 20px;
          //   right: -50px;
          //   width: 180px;
          //   text-align: center;
          //   color: #fff;
          //   transform: rotate(45deg);
          //   background: linear-gradient(-48deg, #FBAA5B, #FD8714);
          //   padding: 5px 10px;
          //   box-sizing: border-box;
          // }
          .jb-img {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 137px;
            height: 137px;
          }
          .header{
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            // overflow: hidden;
            .tip{
              position: relative;
              font-size: 48px;
              color: #000000;
              font-weight: bold;
              // width: 245px;
              height: 103px;
              line-height: 103px;
              // background:url('../../assets/tip-bc.png') no-repeat center center;
              background-size: 100% 100%;
              text-align: center;
              padding-left: 20px;
              box-sizing: border-box;
              .tip-img1 {
                position: absolute;
                top: 0;
                left: -60px;
                width: 152px;
                height: 103px;
              }
              .tip-img2 {
                position: absolute;
                top: 30px;
                right: -30px;
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        .card-item{
          background: #fff;
          border-radius: 20px;
          margin-bottom: 70px;
          overflow: hidden;
          .header-img{
            width: 215px;
            height: 109px;
            position: relative;
            margin-bottom: -20px;
            img{
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
            .h-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 190px;
              height: 90px;
              span{
                color: #FFFFFF;
                font-size: 36px;
                font-weight: bold;
                z-index: 1;
              }
            }
          }
          .table-card{
            width: 100%;
              background: #fff;
            .tiotle{
              padding: 31px 39px;
              color: #B4B4B4;
              font-size: 24px;
              box-sizing: border-box;
              background: #EEF5FD;
            }
            .t-cont{
              display: flex;
              width: 100%;
              .product-name{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 28px 26px;
                box-sizing: border-box;
                width: 200px;
                border-bottom: 1px solid #F1F1F1;
                span {
                  font-size: 24px;
                  font-weight: 400;
                  color: #929292;
                }
              }
              .fun-name{
                flex: 1;
              }
              .td-name{
                display: flex;
                flex-wrap: wrap;
                border-left: 1px solid #F1F1F1;
                border-right: 1px solid #F1F1F1;
                .d-ct{
                  width: 100%;
                  box-sizing: border-box;
                  // border-top: 1px solid #F1F1F1;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #F1F1F1;
                  span{
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: #121212;
                    font-size: 24px;
                    flex: 1;
                    padding: 28px 26px;
                    box-sizing: border-box;
                    border-right: 1px solid #F1F1F1;
                  }
                  .img-div{
                    img{
                      width: 38px;
                      height: 38px;
                    }
                    display: flex;
                    align-items: center;
                    padding: 28px 26px;
                    box-sizing: border-box;
                  }
                }
                div:nth-of-type(1){
                  border-top: none;
                }
              }
              .check-name{
                div{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
.van-dom-sw{
  width: 100%;
  // height: 593px;
  /deep/.van-swipe__track{
    height: auto;
  }
  .van-swipe-item{
    width: 100%;
    // height: 493px;
  }
}
.custom-indicator{
  display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  .item{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #A2D1FF;
    margin: 0px 20px;
  }
  .active{
    background: #fff;
  }
}
.details-sys {
  box-sizing: border-box;
  width: 706px;
  background: #fff;
  border-radius: 20px;
  padding: 40px 20px 40px 20px;
  .details-introduce {
    margin-bottom: 40px;
  }
  ::v-deep img{
    max-width:100%;
    height: auto;
  }
}
.sticky-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 514px;
  height: 88px;
  background: #FFF0D1;
  border-radius: 8px;
  margin: 0 auto;
  span {
    font-size: 36px;
    font-weight: 500;
    color: #C66D1E;
    margin: 0px 115px;
  }
  .box-img {
    width: 24px;
    height: 24px;
  }
  .box-right {
    transform: rotateY(-180deg);
  }
}
</style>