<template>
  <div  class="bot-dom">
    <!-- <div class="botttom-tip">
      为你提供最合适的专业意见及整合方案，改善贵公司的生产力，以及加速数字化转型
    </div> -->
    <div class="compay-tip">
      <span>©2015-2023 北京巨龟科技有限责任公司 copyright 京ICP备16016335号-4   电信增值业务经营许可证：京B2-20213613</span>
    </div>
    <div class="code-ul">
      <div class="code-li" v-for="(item, index) in codeList" :key="index">
        <img class="code-img" :src="require(`@/assets/images/home/qr-code/code${item.code}.png`)" alt="">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="bottom-menu">
      <a href="tel:400-900-9355" class="item">
        <i class="iconfont icon-dianhua1"></i>
        <span>电话咨询</span>
        <a href = “tel:400-500-600”></a>
        <span></span>
      </a>
      <div class="item" @click="goKf">
        <i class="iconfont icon-kefu"></i>
        <span>微信客服</span>
        <span></span>
      </div>
      <div class="item" @click="modalOff(true)">
        <i class="iconfont icon-show-fill"></i>
        <span>预约演示</span>
      </div>
    </div>
    <!-- <div class="box-fixed">
      <div class="wx-box">
        <img class="wx-img" src="@/assets/images/weixin.png" alt="">
      </div>
    </div> -->
    <MakeAppointment :modalType="makeShow" @modalOff="modalOff"></MakeAppointment>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk';
  export default {
    components: {
      MakeAppointment: resolve => (require(['@/views/components/confirm-modal/make-appointment.vue'], resolve)) // 预约演示
    },
    data() {
      return {
        codeList: [
          { code: '1', name: '和诚签' },
          { code: '3', name: '聚人气' },
          { code: '2', name: '聚优企公众号' },
          { code: '4', name: '聚优企视频号' }
        ],
        showPopover: false,
        makeShow: false
      }
    },
    methods:{
      modalOff (val) {
        this.makeShow = val
      },
      wxConfig(timestamp, nonceStr, signature) {
				wx.config({
					debug: true, // 开启调试模式,
					appId: 'wx55923d0ea988444b', // 必填，企业号的唯一标识
					timestamp: timestamp, // 必填，生成签名的时间戳
					nonceStr: nonceStr, // 必填，生成签名的随机串
					signature: signature, // 必填，签名
					jsApiList: ['scanQRCode', 'checkJsApi'], // 必填，需要使用的JS接口列表
				});
				wx.ready(() => {
					console.log('配置完成，扫码前准备完成')
				})
				wx.error(function(res) {
					alert('出错了：' + res.errMsg); //wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
				});
			},
      goKf(){
        console.log('的说法的说', wx)
        location.assign('https://work.weixin.qq.com/kfid/kfcd6a640f9b41f2fe4')
        // wx.miniProgram.navigateTo({ url: '/pages/custom/custom' });
        // wx.openCustomerServiceChat({
        //   extInfo: {
        //   url: 'https://work.weixin.qq.com/kfid/kfc947c26bcbfb70d84',
        //   },
        //   corpId: 'wx55923d0ea988444b',
        //   complete(res) {
        //     console.log('sdsad',res);
        //   },
        // })
      }
    }
  }
</script>
<style lang="less" scoped>
.bot-dom{
  width: 100%;
  padding-bottom: 98px;
  background: #3A3C41;
  .botttom-tip{
    background: #0063F9;
    width: 100%;
    padding: 47px 45px;
    box-sizing: border-box;
    color: #fff;
    font-size: 28px;
    line-height: 46px;
  }
  .compay-tip{
    // background: #3A3C41;
    width: 100%;
    padding: 32px 40px 51px 40px;
    box-sizing: border-box;
    color: #fff;
    font-size: 24px;
    line-height: 46px;
    text-align: center;
    span {
      font-size: 24px;
      line-height: 46px;
      color: rgba(254, 254, 254, 1);
    }
  }
  .code-ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    // background: #3A3C41;
    padding-bottom: 18px;
    .code-li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 311px;
      // background: #FFFFFF;
      border-radius: 8px;
      padding: 9px 0px 17px 0px;
      margin: 0px 19px 17px 0px;
      .code-img {
        width: 156px;
        height: 156px;
        margin-bottom: 12px;
      }
      span {
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .code-li:nth-child(2n) {
      margin-right: 0px;
    }
  }
  .bottom-menu{
    position: fixed;
    z-index: 999;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    // padding: 30px 0px;
    height: 98px;
    background: #fff;
    .item{
      flex: 1;
      color: #006EFF;
      font-size: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        margin-right: 7px;
        font-size: 35px;
      }
      span {
        font-size: 30px;
        font-weight: 500;
        color: #006EFF;
      }
      span:nth-of-type(2){
        display: inline-block;
        width: 1px ;
        height: 32px;
        position: absolute;
        // top: 0px;
        background: #0063F9;
        right: 0px;
      }
    }
  }
  .box-fixed {
    position: fixed;
    z-index: 999;
    top: 400px;
    right: 10px;
    width: 100px;
    height: 100px;
  }
  .wx-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background: #0063F9;
    border-radius: 50%;
    .wx-img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>